import Link from "next/link";
import { useRouter } from "next/router";
import useSWR from "swr";

import { Footer } from "@/components/home/footer";
import Hero from "@/components/home/hero";
import HowSecondLabWorks from "@/components/home/how-it-works";
import { SearchEquipment } from "@/components/home-header";
import { ListingCard } from "@/components/home-listings/listing-card";
import { Loading } from "@/components/loading";
import { ButtonLink } from "@/components/ui/button";
import { getUserType, superFetchData } from "@/lib/utils";
import useAuthStore from "@/stores/auth-store";

export function Home() {
  const router = useRouter();
  const { user } = useAuthStore();
  const userType = getUserType(user);
  const { data: listingData, isLoading } = useSWR(
    "/listings/search?limit=16&is_featured=true&sort=created_desc",
    superFetchData,
  );

  return (
    <div className="bg-interface-white">
      <div className="responsive-spacing-1st-order">
        <div className="items-center gap-10 lg:flex xl:gap-20">
          <div className="lg:w-1/2">
            <h1 className="h1-lp">
              Easily access research services and equipment at tier 1 core
              facilities like{" "}
              <span className="whitespace-normal whitespace-nowrap text-gold-300">
                UC Berkeley
              </span>
            </h1>
            <div className="p2 mt-6 text-emerald-900">
              Second Lab is the #1 marketplace for instrument time and
              processing services at R1 core facilities.  Accelerate R&D
              timelines, reduce costs, and guarantee quality  by working with
              the world’s best research institutions.
            </div>
            <div className="mt-10">
              <ButtonLink
                className="w-full bg-interface-black md:h-14 md:max-w-[400px]"
                href="/signup/business"
              >
                Request Access
              </ButtonLink>
            </div>
          </div>
          <Hero />
        </div>
      </div>

      <div className="bg-gold-50">
        <div className="responsive-spacing pt-14 md:py-10">
          <h2 className="text-center text-emerald-900">
            Seamless Access to Research Services and Facilities at <br />
            Tier 1 Universities in Under 24 Hours
          </h2>
          <div className="p2 mx-auto mt-3 max-w-[756px] text-center text-coffee-900">
            Accelerate your R&D efforts and save money with services and
            machines from the world’s leading research institutions.
          </div>
          <div className="mx-auto h-full max-w-[496px]">
            <img
              src="/ucb-logo-blue.svg"
              alt="UC Berkeley Research"
              className="h-full w-full object-cover md:w-[496px]"
            />
          </div>
        </div>
      </div>

      {/* <div className="bg-gold-50 px-8 py-4 md:px-20">
        <div className="m-auto flex max-w-screen-xl items-center justify-between gap-4 md:justify-center md:gap-52">
          <div>
            <img
              src="/home/uc-berkeley.png"
              alt="uc-berkely"
              className="w-full"
            />
          </div>
          <div>
            <img
              src="/home/uc-sandiago.png"
              alt="uc-sandiago"
              className="w-full"
            />
          </div>
          <div>
            <img src="/home/uc-irvine.png" alt="uc-irvine" className="w-full" />
          </div>
        </div>
      </div> */}

      {/* <div className="px-4 pb-10 pt-14 md:pb-[120px] md:pt-[112px]">
        <div className="md:p1-medium text-center text-gold-500">
          Our Testimonials
        </div>

        <div className="h2-bold text-interface-black m-auto mt-3 max-w-[756px] text-center">
          Trusted by leading enterprises and groundbreaking startups to
          accelerate their research
        </div>
        <div className="m-auto mt-5 flex flex-col gap-6 md:mt-16 md:max-w-screen-xl md:flex-row">
          <div className="mt-12 bg-gold-50 px-6 py-10 md:mt-6">
            <div className="-mt-[72px] mb-2 text-center">
              <img
                src="/home/quotes.svg"
                alt="quotes"
                className="inline-block"
              />
            </div>
            <div>
              Second Lab has revolutionized my research process. It offers
              seamless booking of high-quality lab services and equipment from
              top-tier research labs, all in one convenient platform. The
              intuitive interface, reliable resources, and excellent customer
              support have significantly enhanced my productivity and research
              outcomes. I highly recommend it to any researcher looking to
              streamline their lab needs and access the best tools available.
            </div>
            <div className="mt-6 flex items-center justify-between">
              <div>
                <div className="subtitle-1 text-interface-black">John Doe</div>
                <div className="p3">Researcher at Nessie Lab</div>
              </div>
              <img
                src="/home/nessie.png"
                alt="nessie"
                className="h-12 w-12 rounded-full"
              />
            </div>
          </div>
          <div className="mt-6 bg-gold-50 px-6 py-10">
            <div className="-mt-[72px] mb-2 text-center">
              <img
                src="/home/quotes.svg"
                alt="quotes"
                className="inline-block"
              />
            </div>
            <div>
              Second Lab has revolutionized my research process. It offers
              seamless booking of high-quality lab services and equipment from
              top-tier research labs, all in one convenient platform. The
              intuitive interface, reliable resources, and excellent customer
              support have significantly enhanced my productivity and research
              outcomes. I highly recommend it to any researcher looking to
              streamline their lab needs and access the best tools available.
            </div>
            <div className="mt-6 flex items-center justify-between">
              <div>
                <div className="subtitle-1 text-interface-black">John Doe</div>
                <div className="p3">Researcher at Nessie Lab</div>
              </div>
              <img
                src="/home/nessie.png"
                alt="nessie"
                className="h-12 w-12 rounded-full"
              />
            </div>
          </div>
          <div className="mt-6 bg-gold-50 px-6 py-10">
            <div className="-mt-[72px] mb-2 text-center">
              <img
                src="/home/quotes.svg"
                alt="quotes"
                className="inline-block"
              />
            </div>
            <div>
              Second Lab has revolutionized my research process. It offers
              seamless booking of high-quality lab services and equipment from
              top-tier research labs, all in one convenient platform. The
              intuitive interface, reliable resources, and excellent customer
              support have significantly enhanced my productivity and research
              outcomes. I highly recommend it to any researcher looking to
              streamline their lab needs and access the best tools available.
            </div>
            <div className="mt-6 flex items-center justify-between">
              <div>
                <div className="subtitle-1 text-interface-black">John Doe</div>
                <div className="p3">Researcher at Nessie Lab</div>
              </div>
              <img
                src="/home/nessie.png"
                alt="nessie"
                className="h-12 w-12 rounded-full"
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="max-w-screen bg-interface-black">
        <div className="no-scrollbar m-auto flex items-center gap-8 overflow-x-auto p-4 md:max-w-screen-xl md:justify-between">
          <div className="shrink-0">
            <img src="/home/brands/regeneron.svg" alt="regeneron" />
          </div>
          <div className="shrink-0">
            <img src="/home/brands/gilead.svg" alt="gilead" />
          </div>
          <div className="shrink-0">
            <img src="/home/brands/msd.svg" alt="msd" />
          </div>
          <div className="shrink-0">
            <img src="/home/brands/prothena.svg" alt="prothena" />
          </div>
          <div className="shrink-0">
            <img src="/home/brands/rarebase.svg" alt="rarebase" />
          </div>
          <div className="shrink-0">
            <img src="/home/brands/abbvie.svg" alt="abbvie" />
          </div>
          <div className="shrink-0">
            <img src="/home/brands/karuna.svg" alt="karuna" />
          </div>
          <div className="shrink-0">
            <img src="/home/brands/astellas.svg" alt="astellas" />
          </div>
        </div>
      </div> */}

      {/* <div className="overflow-hidden py-14 md:py-28"> */}

      <HowSecondLabWorks />
      <div className="responsive-spacing overflow-hidden">
        <h2 className="h2-lp text-center text-emerald-900">
          Reserve Your Machine Or Service Today
        </h2>
        <div className="mx-auto mt-10 w-full md:max-w-[668px]">
          <SearchEquipment
            placeholder="Search for equipment and services"
            onSubmit={(search: string) => {
              router.push(`/listings/?text=${search}`);
            }}
          />
        </div>
        <div className="m-auto mt-12">
          {isLoading && <Loading />}
          <ul className="mt-3 grid auto-rows-auto grid-cols-1 divide-y divide-gold-200 md:mx-0 md:mt-7 md:grid-cols-2 md:gap-4 md:divide-y-0 md:px-4 lg:grid-cols-4 lg:gap-6 xl:grid-cols-4 xl:px-0">
            {(listingData?.listings || []).map((listing: any) => (
              <li key={listing.slug}>
                <ListingCard
                  listing={listing}
                  className="bg-interface-white"
                  userType={userType}
                />
              </li>
            ))}
          </ul>
          <div className="mt-6 text-center">
            <Link className="b3 text-title hover:underline" href="/listings">
              See More
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
