import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import React from "react";

import { getRefreshToken } from "@/components/auth/api";
import { SwitchingRole } from "@/components/common/switching-role";
import { PublicMenu } from "@/components/layout/public-menu";
import { Sidebar } from "@/components/sidebar";
import { cn, getUserType } from "@/lib/utils";
import useAuthStore from "@/stores/auth-store";
import useOrgStore from "@/stores/org-store";
import useSideBarStore from "@/stores/sidebar-store";

function Loading() {
  return (
    <>
      <Head>
        <title>Second Lab</title>
        <meta name="description" content="Second Lab" />
      </Head>
    </>
  );
}

export default function MainLayout({
  children,
  isPublic,
  metadata,
}: {
  children: React.ReactNode;
  isPublic?: boolean;
  metadata?: any;
}) {
  const { init: initOrg } = useOrgStore();
  const router = useRouter();
  const { user, token, tokenInvalid, ready, switchingRole } = useAuthStore();
  const { expanded, init: initSidebar } = useSideBarStore();
  const triggerRefreshRef = useRef(null);

  const userType = getUserType(user);

  //Check supported browser
  // useEffect(() => {
  //   const { browserName, version } = checkBrowserInfo();
  //   if (!browserName || version < supportedBrowsers[browserName]) {
  //     router.push(pagePath?.error?.unSupported(userType === "university"));
  //   }
  // }, []);

  // Init org store
  useEffect(() => {
    initOrg();
  }, [initOrg]);

  // Init sidebar
  useEffect(() => {
    initSidebar();
  }, [initSidebar]);

  if (!isPublic) {
    // decode token & check expired & check user role
    if (!ready) {
      return <Loading />;
    }

    if (token && tokenInvalid) {
      getRefreshToken(triggerRefreshRef);
      return <Loading />;
    }

    if (!token) {
      const path = window.location.pathname;
      window.location.href = `/login?redirect=${path}`;
      return <Loading />;
    }

    if (!user.org_name || user.org_name.length == 0) {
      if (user.user_type == "company") {
        window.location.href = `/company-setup/intro`;
      } else {
        window.location.href = `/organization-setup/intro`;
      }
      return <Loading />;
    }
  }

  return (
    <div className="overflow-x-hidden bg-coffee-50">
      <a href="#main" className="skip-link">
        SKIP TO CONTENT
      </a>
      {!!user && <Sidebar />}
      <div
        className={cn(
          "transition-all",
          expanded ? "md:pl-[300px]" : "md:pl-[76px]",
          user ? "pt-[81px] md:pt-0" : "md:pl-0",
        )}
      >
        <main className="relative h-full h-screen" id="main">
          {!user && <PublicMenu metadata={metadata} />}
          {switchingRole ? (
            <SwitchingRole userType={userType} />
          ) : (
            <div className="main-children h-full">{children}</div>
          )}
          {/* {(user === null || userType === "company") && (
            <div
              className={cn(
                "fixed bottom-0 left-0 right-0 w-full bg-[#EEEEEE] transition-all",
                expanded ? "md:pl-[300px]" : "md:pl-[76px]",
                !user && "md:pl-0",
              )}
            >
              <div className="justify-between px-5 py-3 md:flex">
                <div className="divide-x">
                  <a
                    className="b5 pr-3"
                    href={`${getHostingUrl(userType)}/terms`}
                  >
                    Terms and condition
                  </a>
                  <a
                    className="b5 pl-3"
                    href={`${getHostingUrl(userType)}/privacy-policy`}
                  >
                    Privacy Policy
                  </a>
                </div>
                <div className="p4-mobile mt-2 text-black md:mt-0">
                  <span className="align-middle">Copyright © 2024</span>{" "}
                  <span className="p4-bold-mobile align-middle">SecondLab</span>
                </div>
              </div>
            </div>
          )} */}
        </main>
      </div>
    </div>
  );
}
