import useSWR, { SWRConfiguration } from "swr";

import { superFetchData } from "@/lib/utils";
import { NobfType } from "@/src/models/common.model";

const userService = {
  useInfo: () => {
    return useSWR(`/users/info`, superFetchData);
  },
  useLabProfile: (orgId: number, type?: NobfType) => {
    const preferBookingType = (() => {
      if (type === "scientific_process") {
        return "equipment";
      }
      if (!type || ["organization", "banner"].includes(type)) {
        return "auto";
      }
      return type;
    })();
    const url = `/users/lab-profile?lab_id=${orgId}&prefer_booking_type=${preferBookingType}`;
    return useSWR(orgId ? url : null, superFetchData);
  },
  fetchLabProfile: (orgId: number, type?: NobfType) => {
    const preferBookingType = (() => {
      if (type === "scientific_process") {
        return "equipment";
      }
      if (!type || ["organization", "banner"].includes(type)) {
        return "auto";
      }
      return type;
    })();
    const url = `/users/lab-profile?lab_id=${orgId}&prefer_booking_type=${preferBookingType}`;
    return superFetchData(url);
  },

  useCreateSubscription: (orgId: number) => {
    return useSWR(`/users/subscriptions?org_id=${orgId}`, superFetchData);
  },
  useSubscription: (orgId: number) => {
    return useSWR(`/users/subscriptions?org_id=${orgId}`, superFetchData);
  },
  useRequiredTrainings: (orgId: number) => {
    return useSWR(
      `/users/lab-required-trainings?lab_org_id=${orgId}`,
      superFetchData,
    );
  },
  useApprovals: (key: string | null, options?: SWRConfiguration) => {
    return useSWR(
      key === null ? null : key ? `/users/approvals${key}` : "/users/approvals",

      superFetchData,
      options,
    );
  },
};

export default userService;
