import "@/styles/fonts.css";
import "@/styles/global.css";

import { NextPage } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";
import NextNProgress from "nextjs-progressbar";
import { ReactElement, ReactNode, useEffect, useRef } from "react";
import { SWRConfig } from "swr";

import ErrorBoundary from "@/components/common/error-boundary";
import { Toaster } from "@/components/ui/toaster";
import useAuthStore from "@/stores/auth-store";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, pageProps?: any) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  const { init } = useAuthStore();

  const ref = useRef<any>(null);
  useEffect(() => {
    if (ref.current) return;
    init();
    return () => {
      ref.current = true;
    };
  }, [init]);

  return getLayout(
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <ErrorBoundary>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            shouldRetryOnError: false,
          }}
        >
          <div>
            <NextNProgress
              color="#232D2A"
              showOnShallow={false}
              options={{ showSpinner: false }}
            />
            <Component {...pageProps} />
            <Toaster />
          </div>
        </SWRConfig>
      </ErrorBoundary>
    </>,
    pageProps,
  );
}
