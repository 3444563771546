import { ChevronDown, Menu, X } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";

import {
  Accordion,
  AccordionContentMenu,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { ButtonLink } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/sheet";

export function PublicMenu({ metadata }: any) {
  const [openSolution, setOpenSolution] = useState(false);
  const router = useRouter();

  const redirect = router.pathname !== "/" ? `?redirect=${router.asPath}` : "";
  let requestAccessRedirect = "";
  if (metadata && metadata.page_type == "lab_detail" && metadata.org) {
    requestAccessRedirect = `?referrer_org_id=${metadata.org.id}&redirect=${router.asPath}&referrer_source=organization`;
  }

  return (
    <div className="bg-interface-black p-4 md:py-6 xl:px-10">
      <div className="m-auto flex items-center justify-between">
        <div className="flex items-center gap-10 lg:gap-20">
          <Link href="/">
            <img
              className="h-[12px] md:h-auto"
              src="/nav-logo.svg"
              alt="logo"
            />
          </Link>
          <div className="hidden items-center gap-5 md:flex lg:gap-10">
            <Link
              href="/about"
              className={`p4-medium flex items-center gap-2 hover:text-gold-200 focus:text-gold-200 active:text-gold-200 ${router.pathname === "/about" ? "text-gold-200" : "text-interface-background"}`}
            >
              About
            </Link>
            <div>
              <DropdownMenu open={openSolution} onOpenChange={setOpenSolution}>
                <DropdownMenuTrigger
                  className={`p4-medium flex items-center text-interface-background hover:text-gold-200 focus:text-gold-200 active:text-gold-200`}
                >
                  Listings <ChevronDown />
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  sideOffset={12}
                  side="top"
                  className="!ml-10"
                >
                  <DropdownMenuItem>
                    <Link
                      className="p4-medium text-title"
                      href="/listings"
                      onClick={() => setOpenSolution(false)}
                    >
                      All
                    </Link>
                  </DropdownMenuItem>

                  <DropdownMenuItem>
                    <Link
                      className="p4-medium text-title"
                      href="/listings?type=equipment"
                      onClick={() => setOpenSolution(false)}
                    >
                      Equipment
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <Link
                      className="p4-medium text-title"
                      href="/listings?type=service"
                      onClick={() => {
                        setOpenSolution(false);
                      }}
                    >
                      Services
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <Link
                      className="p4-medium text-title"
                      href="/listings?type=scientific_process"
                      onClick={() => setOpenSolution(false)}
                    >
                      Processes
                    </Link>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <Link
              href="/career"
              className={`p4-medium flex items-center gap-2 hover:text-gold-200 focus:text-gold-200 active:text-gold-200 ${router.pathname === "/career" ? "text-gold-200" : "text-interface-background"}`}
            >
              Careers
            </Link>
          </div>
        </div>
        <div className="hidden items-center gap-5 md:flex lg:gap-10">
          <Link
            href="/list-your-lab"
            className={`p4-medium flex items-center gap-2 hover:text-gold-200 focus:text-gold-200 active:text-gold-200 ${router.pathname === "/list-your-lab" ? "text-gold-200" : "text-interface-background"}`}
          >
            List Your Lab
          </Link>
          <Link
            href={`/login${redirect}`}
            className={`p4-medium flex items-center gap-2 text-interface-background hover:text-gold-200 focus:text-gold-200 active:text-gold-200`}
          >
            Sign in
          </Link>

          <ButtonLink
            variant="gold"
            href={`/signup/business${requestAccessRedirect}`}
            className="!p4-medium h-11 md:w-[200px]"
          >
            Request Access
          </ButtonLink>
        </div>
        <SideMenu />
      </div>
    </div>
  );
}

function SideMenu() {
  const [open, setOpen] = useState(false);

  function close() {
    setOpen(false);
  }

  return (
    <div className="md:hidden">
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetTrigger className="flex justify-center text-coffee-100">
          <Menu />
        </SheetTrigger>
        <SheetContent
          className="border-r-0 bg-interface-black p-0 text-coffee-100"
          side="left"
          showCloseButton={false}
        >
          <div className="flex h-screen flex-col justify-between">
            <div>
              <div className="flex items-center justify-between p-4">
                <Link onClick={close} href="/">
                  <img src="/nav-logo.svg" alt="logo" />
                </Link>
                <SheetClose>
                  <X />
                  <span className="sr-only">Close</span>
                </SheetClose>
              </div>
              <div className="p4-mobile mt-8 *:text-interface-white">
                <Link
                  onClick={close}
                  href="/about"
                  className="block border-t border-coffee-800 p-4"
                >
                  About
                </Link>
                <Accordion
                  type="single"
                  collapsible
                  className="border-t border-coffee-800"
                >
                  <AccordionItem value="item-1" className="border-0">
                    <AccordionTrigger className="bg-transparent px-4 text-xs text-interface-white hover:bg-transparent hover:no-underline">
                      Solutions
                    </AccordionTrigger>
                    <AccordionContentMenu className="bg-coffee-900 p-0">
                      <Link
                        onClick={close}
                        href="/listings?type=equipment"
                        className="block border-t border-coffee-800 p-4 pl-8 text-xs text-interface-white"
                      >
                        Equipment
                      </Link>
                      <Link
                        onClick={close}
                        href="/listings?type=service"
                        className="block border-t border-coffee-800 p-4 pl-8 text-xs text-interface-white"
                      >
                        Services
                      </Link>
                    </AccordionContentMenu>
                  </AccordionItem>
                </Accordion>
                <Link
                  onClick={close}
                  href="/career"
                  className="block border-t border-coffee-800 p-4"
                >
                  Careers
                </Link>
                <Link
                  onClick={close}
                  href="/list-your-lab"
                  className="block border-t border-coffee-800 p-4"
                >
                  List Your Lab
                </Link>
              </div>
            </div>
            <div className="space-y-3 p-4">
              <ButtonLink
                variant="gold"
                className="b5 h-11 w-full"
                href="/signup/business"
              >
                Request Access
              </ButtonLink>
              <ButtonLink
                variant="outline"
                className="b5 h-11 w-full border-2 border-gold-200 text-gold-200"
                href="/login"
              >
                Sign in
              </ButtonLink>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
}
