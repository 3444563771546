import { Navigation } from "lucide-react";
import Link from "next/link";
import React from "react";

import {
  getListingTypeName,
  LISTING_TYPE,
} from "@/components/common/constants";
import { ImageResponsive } from "@/components/image";
import { addParams, cn, getHostingUrl, getListingPath } from "@/lib/utils";

export function ListingCard({ listing, className, userType }: any) {
  const mapParams = {
    api: 1,
    map_action: "map",
    destination: listing?.location || "",
  } as any;
  const mapParamString = encodeURI(
    Object.keys(mapParams)
      .map((k) => `${k}=${mapParams[k]}`)
      .join("&"),
  );
  const footer = (
    <div className="flex items-center justify-between gap-2 border-gold-200 text-title  md:border-t md:pt-4">
      <div className="flex grow items-start gap-2">
        <div className="">
          <div className="p4-medium-mobile md:p3-bold-mobile line-clamp-1">
            <Link
              href={`${getHostingUrl(userType)}/organizations/${listing.org_slug}-${listing?.org_id}`}
              target="_blank"
              className="p4-bold-mobile md:l3 line-clamp-1 text-emerald-900"
            >
              {listing.university_name}
            </Link>
          </div>
          <div className="flex items-end gap-2">
            <Link
              className="p4-mobile line-clamp-2 text-xxs text-emerald-900 md:text-xs"
              href={`${getHostingUrl(userType)}/organizations/${listing.org_slug}-${listing.org_id}`}
              target="_blank"
            >
              {listing.org_name}
            </Link>
            <div className="p4-mobile text-coffee-300">|</div>
            <div className="flex">
              <div className="p4-medium-mobile md:p4 mr-1 h-[21px] whitespace-nowrap pt-[2px] text-emerald-900">
                {(listing.distance || 0).toFixed()} miles
              </div>
              <Link
                className="flex shrink-0 items-center gap-2 pt-[4px]"
                target="_blank"
                href={`https://www.google.com/maps/dir/?${mapParamString}`}
                onClick={(e) => e.stopPropagation()}
              >
                <Navigation
                  size={16}
                  strokeWidth={1}
                  className="shrink-0 text-emerald-black"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const listTypeColor = (() => {
    switch (listing?.type) {
      case LISTING_TYPE.EQUIPMENT:
        return "bg-gold-100";
      case LISTING_TYPE.SERVICE:
        return "bg-coffee-100";
      case LISTING_TYPE.SCIENTIFIC_PROCESS:
        return "bg-emerald-400";
      default:
        return "";
    }
  })();

  const EquipmentAndScientificPricing = () => {
    return listing.requires_subscription &&
      listing.price_included_in_subscription ? (
      <div className="md:p3-medium-mobile mb-3 text-emerald-500">
        Covered by Subscription
      </div>
    ) : (
      <>
        {listing?.type === "scientific_process" ? (
          <div className="mb-3 flex items-center justify-start gap-0.5 md:gap-1">
            <div className="h6-mobile md:p1-medium !text-emerald-500">
              {listing.price === 0 ? (
                <span className="md:h2 !text-emerald-500">
                  {listing.price_unit}
                </span>
              ) : (
                <>
                  From{" "}
                  <span className="md:p1-medium !text-emerald-500">
                    ${listing.price}
                  </span>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="mb-3 flex items-center gap-0.5 md:gap-1">
            <div className="h6-mobile md:p1-medium !text-emerald-500">
              {`$${listing.price}`}
            </div>
            <span className="p3-mobile md:p1-medium text-emerald-500">
              {listing.price_unit === "months"
                ? "/month"
                : listing.price_unit === "days"
                  ? "/day"
                  : "/h"}
            </span>
          </div>
        )}
      </>
    );
  };

  const ServicePricing = () => {
    return (
      <div className="mb-3 flex items-center gap-0.5 md:gap-1">
        <div className="h6-mobile md:p1-medium !text-emerald-500">
          {listing.price === 0 ? (
            listing.price_unit === "Contact for pricing" ? (
              <span className="md:p3-bold-mobile !text-emerald-500">
                Contact for pricing
              </span>
            ) : (
              <span className="md:p1-medium !text-emerald-500">
                {listing.price_unit}
              </span>
            )
          ) : (
            <span>
              From{" "}
              <span className="md:p1-medium !text-emerald-500">
                ${listing.price}
              </span>
            </span>
          )}
        </div>
      </div>
    );
  };

  const TagSection = () => {
    return (
      <div className="mb-3 flex flex-wrap items-center gap-2">
        <div
          className={`flex h-7 min-w-[112px] items-center justify-center rounded-sm ${listTypeColor} px-2`}
        >
          <span
            className={`p5 ${listing.type === "scientific_process" ? "text-white" : "text-emerald-900"}`}
          >
            {" "}
            {getListingTypeName(listing?.type)}
          </span>
        </div>

        {listing.type !== "service" &&
          (listing.user_subscribed || listing.requires_subscription) && (
            <div
              className={`flex h-7 min-w-[112px] items-center justify-center rounded-sm bg-coffee-50 px-2`}
            >
              <span className={`p5 text-emerald-900`}>
                {listing.user_subscribed
                  ? "Subscribed"
                  : "Subscription Required"}
              </span>
            </div>
          )}
      </div>
    );
  };
  return (
    <div
      className={cn(
        "block h-full bg-background p-4 md:rounded-lg md:pb-8",
        className,
        "hover:bg-coffee-50",
      )}
    >
      <div className="relative flex h-full flex-col justify-between">
        <Link
          href={`${getHostingUrl(userType)}/${getListingPath(listing?.type)}/${listing?.slug}`}
          className="flex justify-between gap-3 md:h-full md:flex-col"
          target="_blank"
        >
          <div className="relative h-[182px] w-[111px] shrink-0 overflow-hidden rounded-lg md:h-40 md:w-full">
            <ImageResponsive
              className="h-full"
              src={addParams(listing?.thumbnail, { width: 460 })}
              alt={listing?.name}
              width="100%"
              height="100%"
            />
          </div>

          <div className="flex h-full grow flex-col justify-between py-3 md:mt-4 md:py-0">
            <TagSection />
            <div className="mb-2 flex justify-between gap-2">
              <div className="p3-bold-mobile md:p3-bold line-clamp-2 overflow-ellipsis text-emerald-900">
                {listing?.name}
              </div>
            </div>
            <div className="p4 line-clamp-2 h-[42px] text-sm text-coffee-800">
              {listing?.description}
            </div>
            {listing?.type === "service" ? (
              <ServicePricing />
            ) : (
              <EquipmentAndScientificPricing />
            )}

            <div>{footer}</div>
          </div>
        </Link>

        <div className="bottom-0 right-0 hidden w-[calc(100%-124px)] md:relative md:w-auto">
          {footer}
        </div>
      </div>
    </div>
  );
}
