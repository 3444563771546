import { ClassValue, clsx } from "clsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import jwtDecode from "jwt-decode";
import { keyBy } from "lodash-es";
import { twMerge } from "tailwind-merge";

import { LISTING_TYPE } from "@/components/common/constants";
import { toast } from "@/components/ui/use-toast";
import { NobfParams } from "@/src/models/common.model";

export const API_URL = `${process.env.NEXT_PUBLIC_API_URL}/api/v1`;
export const API_URL_V2 = `${process.env.NEXT_PUBLIC_API_URL}/api/v2`;
export const API_URL_ADMIN = `${process.env.NEXT_PUBLIC_API_URL}/api/admin`;

export const SITE_URL = process.env.NEXT_PUBLIC_API_URL;

interface RequestError extends Error {
  status: Number;
  code: string;
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// get auth token based on url contain hosting or not
export function getAuthToken(): string | null {
  if (typeof localStorage === "undefined") return null;
  try {
    const userJson = localStorage.getItem("user") || "";
    if (!userJson) return null;
    const user = JSON.parse(userJson);
    const tokenByUserType = keyBy(user.user_tokens, "user_type");

    const user_type = location.pathname.includes("/hosting")
      ? "university"
      : "company";
    // return access_token from user_tokens array else return token from user
    return tokenByUserType[user_type]?.access_token || user.token || null;
  } catch {
    return null;
  }
}

export const superFetch = async <T>(
  url: string,
  init?: RequestInit,
  type?: string,
): Promise<T> => {
  const headers = new Headers({
    ...init?.headers,
  });
  const token = getAuthToken();
  if (token !== null) headers.append("secondlab-token", token);
  const response = await fetch(url, { ...init, headers });

  if ([400, 402, 403, 401, 404].includes(response.status)) {
    const error = new Error(
      "An error occurred while fetching the data.",
    ) as RequestError;
    // Attach extra info to the error object.
    const json = await response.json();
    error.message = json.message;
    error.code = json.code ?? "";
    error.status = response.status;
    throw error;
  }

  if ([500].includes(response.status)) {
    const error = new Error(
      "Server error. Please try again later.",
    ) as RequestError;
    error.status = response.status;
    throw error;
  }

  if (type === "blob") return response.blob() as any;

  return response.json();
};

export async function superFetchData(url: string) {
  const response = (await superFetch(`${API_URL}${url}`)) as any;
  return response.data;
}

export async function superFetchDataV2(url: string) {
  const response = (await superFetch(`${API_URL_V2}${url}`)) as any;
  return response.data;
}

export async function superFetcher(url: string) {
  const response = (await superFetch(`${API_URL}${url}`)) as any;
  return response;
}

export async function fetcher(url: string) {
  const response = await fetch(`${API_URL}${url}`);
  const json = await response.json();
  return json.data as any;
}

export function handleError(error: any, title?: string, duration?: number) {
  toast({
    title,
    description: error.message || String(error),
    variant: "destructive",
    duration: duration || 5000,
  });
  console.error(error);
}

export function encodeQueryData(data: any) {
  const ret = [];

  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      if (Array.isArray(data[key])) {
        data[key].forEach((value: any) => {
          if (value !== undefined) {
            ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
          }
        });
      } else if (data[key] !== undefined) {
        ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`);
      }
    }
  }

  return ret.length ? `?${ret.join("&")}` : "";
}

export function isTokenExpired(token: string) {
  const decodedToken: any = jwtDecode(String(token));
  return decodedToken.exp * 1000 < Date.now();
}

export function downloadURI(uri: string, name: string) {
  var link = document.createElement("a");
  link.setAttribute("download", name);
  link.href = uri;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function toUSD(amount: number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount / Math.pow(10, 6));
}

export function formatUSD(amount: number | undefined) {
  if (amount === 0 || amount === undefined) return "";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
}

export function splitLines(text: string) {
  if (!text) {
    return [];
  }
  return text.split("\n");
}

export function addParams(url: string, params: any) {
  if (!url) return undefined;
  return `${url}?${new URLSearchParams(params).toString()}`;
}

export function getSlug(str: string) {
  // str = "apple-banana-cherry"
  const lastHyphenIndex = str.lastIndexOf("-");
  if (lastHyphenIndex === -1) return [str, undefined];

  const slug = str.substring(0, lastHyphenIndex); // "apple-banana")
  const id = str.substring(lastHyphenIndex + 1); // "cherry"

  if (isNaN(Number(id))) return [str, undefined];

  return [slug, id];
}
export function parseSlug(str: string) {
  // str = "org-name-id"
  const lastHyphenIndex = str.lastIndexOf("-");
  if (lastHyphenIndex === -1) return { str, id: str };

  const slug = str.substring(0, lastHyphenIndex); // "org-name")
  const id = str.substring(lastHyphenIndex + 1); // "id"

  return { slug, id };
}

export function getOrgSlug(org: any) {
  return `${org.slug}-${org.id}`;
}

export function getSlugId(data: any) {
  if (!data) return "";
  return `${data.slug}-${data.id}`;
}

export function getNameFromFilename(filename: string) {
  if (!filename) return "";
  // Handle file name with dot
  const dotIndex = filename.lastIndexOf(".");
  if (dotIndex === -1) return filename;
  return filename.substring(0, dotIndex);
}

export function arrayUniqueById(array: any) {
  const uniqueById = Array.from(
    new Map(array.map((item: any) => [item.id, item])).values(),
  );
  return uniqueById;
}

// get user type based on url contain hosting or not
export function getUserType(user: any) {
  let userType = user?.user_type;

  if (["university", "company"].includes(userType)) {
    if (location.pathname.includes("/hosting")) {
      userType = "university";
    } else {
      userType = "company";
    }
  }

  return userType;
}

export function getHostingUrl(userType: string) {
  return userType === "university" ? "/hosting" : "";
}

export function getHostingUrlByUser(user: any) {
  return getHostingUrl(getUserType(user));
}

export function formatWorkingHours(weekdays: any) {
  const sortedWeekdays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ].map((day) => {
    const weekday = weekdays[day];
    return {
      ...weekday,
      start_time: weekday.start_time == 0 ? "000" : String(weekday.start_time),
      end_time: String(weekday.end_time),
    };
  });
  return sortedWeekdays;
}

export function workingHoursToWeekdays(workingHours: any) {
  function formatDay(day: any) {
    return {
      open: day.open,
      start_time: Number(day.start_time),
      end_time: Number(day.end_time),
    };
  }
  const weekdays = {
    monday: formatDay(workingHours[0]),
    tuesday: formatDay(workingHours[1]),
    wednesday: formatDay(workingHours[2]),
    thursday: formatDay(workingHours[3]),
    friday: formatDay(workingHours[4]),
    saturday: formatDay(workingHours[5]),
    sunday: formatDay(workingHours[6]),
  };
  return weekdays;
}

export function getListingHomeUrl(user: any) {
  const userType = getUserType(user);
  if (user) {
    return "/listings";
  } else {
    return userType === "university" ? "/hosting" : "/";
  }
}

export function reorder(list: any[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function getBookingType(type: string) {
  if (type === "equipment") {
    return "Equipment";
  }
  if (type === "scientific_process") {
    return "Scientific Process";
  }
  if (type === "service") {
    return "Service";
  }
  return type;
}

export function getListingPath(type: string) {
  if (type === LISTING_TYPE.EQUIPMENT) {
    return "equipments";
  }

  if (type === LISTING_TYPE.SERVICE) {
    return "services";
  }

  if (type === LISTING_TYPE.SCIENTIFIC_PROCESS) {
    return "scientific-process";
  }

  return type;
}

export function getListingUrl({ user, item }: any) {
  if (item.type === LISTING_TYPE.SCIENTIFIC_PROCESS) {
    return `${getHostingUrlByUser(user)}/${getListingPath(item.type)}/${getSlugId(item)}`;
  }
  return `${getHostingUrlByUser(user)}/${getListingPath(item.type)}/${item.slug}`;
}

export function getTimeAccess(key: string): number {
  const val = localStorage.getItem(key);
  if (val == null) {
    localStorage.setItem(key, "1");
    return 1;
  }
  const num = parseInt(val);
  localStorage.setItem(key, (num + 1).toString());
  return num + 1;
}

export const checkBrowserInfo = () => {
  const userAgent: any = navigator.userAgent;

  if (userAgent.includes("Chrome")) {
    return {
      browserName: "chrome",
      version: parseInt(userAgent?.match(/Chrome\/(\d+)/)[1]),
    };
  }

  if (userAgent.includes("Firefox")) {
    return {
      browserName: "firefox",
      version: parseInt(userAgent?.match(/Firefox\/(\d+)/)[1]),
    };
  }

  if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    return {
      browserName: "safari",
      version: parseInt(userAgent?.match(/Version\/(\d+)/)[1]),
    };
  }

  if (userAgent.includes("Edg")) {
    return {
      browserName: "edge",
      version: parseInt(userAgent?.match(/Edg\/(\d+)/)[1]),
    };
  }

  if (userAgent.includes("OPR")) {
    return {
      browserName: "opera",
      version: parseInt(userAgent?.match(/OPR\/(\d+)/)[1]),
    };
  }

  return { version: 0 };
};
export function generatePDF(imageSrc: any) {
  // Create a new jsPDF instance
  const pdf = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
    putOnlyUsedFonts: true,
  });

  const img = new Image();
  img.src = imageSrc;

  // Wait for the image to load
  img.onload = function () {
    const a4Width = 210; // A4 width in mm
    const a4Height = 297; // A4 height in mm
    const imgWidth = img.width * 0.264583; // Convert pixels to mm (1 pixel = 0.264583 mm)
    const imgHeight = img.height * 0.264583; // Convert pixels to mm

    // Calculate scaling factors to fit the image in A4 dimensions
    const widthRatio = a4Width / imgWidth;
    const heightRatio = a4Height / imgHeight;
    const scale = Math.min(widthRatio, heightRatio); // Get the smaller scaling factor

    // Calculate new dimensions
    const newWidth = imgWidth * scale;
    const newHeight = imgHeight * scale;

    // Draw the image on the PDF
    const x = (a4Width - newWidth) / 2; // Center the image on the page
    const y = (a4Height - newHeight) / 2; // Center the image on the page

    pdf.addImage(img, "PNG", x, y, newWidth, newHeight); // Use the appropriate format for your image type
    pdf.save("generated.pdf"); // Save the PDF with a filename
  };
}

export async function generatePdfMultiplePage(
  elementRef: any,
  fileName: string,
) {
  const canvas = await html2canvas(elementRef, { scale: 2 }); // Increase scale for better quality with smaller size
  const imgData = canvas.toDataURL("image/jpeg", 0.75); // Convert to JPEG with 75% quality

  const imgWidth = 210; // A4 width in mm
  const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate height based on aspect ratio
  const pageHeight = imgHeight; // Use the calculated image height as page height

  const pdf = new jsPDF("p", "mm", [imgWidth, pageHeight], true);

  // Determine how many pages you'll need
  let heightLeft = imgHeight;
  let position = 0;

  // While there is still content to add to the PDF
  while (heightLeft > 0) {
    pdf.addImage(
      imgData,
      "JPEG", // Use JPEG format
      0,
      position,
      imgWidth,
      imgHeight,
      undefined,
      "FAST", // Use fast compression for smaller size
    );

    heightLeft -= pageHeight;
    position -= pageHeight; // Move down for the next page

    // Add a new page if there is still more content
    if (heightLeft > 0) {
      pdf.addPage();
    }
  }

  pdf.save(`${fileName}.pdf`);
}

export function getOnboardingFlowParams({
  type,
  orgId,
  backUrl,
  requiredSubscription,
}: NobfParams) {
  const params = new URLSearchParams();
  if (orgId) {
    params.append("org_id", orgId.toString());
  }
  if (backUrl) {
    params.append("back", backUrl);
  }
  if (type) {
    params.append("type", type);
  }
  if (requiredSubscription) {
    params.append("require", "subscription");
  }
  return params.toString();
}

export function getTimeSlotUnit(unit: string) {
  switch (unit) {
    case "minutes":
      return "Hours";
    case "days":
      return "Days";
    case "months":
      return "Months";
  }
  return "";
}
