import { ReloadIcon } from "@radix-ui/react-icons";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import Link from "next/link";
import * as React from "react";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "p3-medium inline-flex text-emerald-black items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:bg-coffee-100 disabled:text-coffee-300 disabled:pointer-events-none ring-offset-background leading-6",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground hover:text-gold-200 active:font-bold",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-emerald-black border-2 hover:bg-emerald-black hover:text-background active:font-bold hover:bg-emerald-black",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-coffee-50 active:font-bold",
        tertiary:
          "border border-emerald-black disabled:border-coffee-200 border-2 hover:text-background active:font-bold hover:bg-emerald-black disabled:bg-white disabled:text-coffee-300 disabled:pointer-events-none",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "underline-offset-4 hover:underline text-interface-link",
        gold: "bg-gold-200 text-interface-black hover:text-interface-grey hover:text-coffee-800 active:font-bold b5 lg:b3",
      },
      size: {
        default: "py-3 px-3 text-lg rounded-sm",
        xs: "h-8 px-3 rounded-sm",
        sm: "py-2 px-3 text-sm rounded-sm",
        lg: "py-3 px-3 text-lg rounded-sm h-[56px]",
        xl: "py-3 px-3 text-lg rounded-sm h-14",
        xm: "h-6 px-2 rounded-sm m-0.5",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  busy?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, busy, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          busy && "pointer-events-none",
        )}
        ref={ref}
        type="button"
        {...props}
      >
        {busy && <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />}
        {props.children}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export interface ButtonLinkProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  href: string;
  target?: string;
  download?: string;
}

const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = (asChild ? Slot : Link) as any;
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        type="button"
        {...props}
      >
        {props.children}
      </Comp>
    );
  },
);

ButtonLink.displayName = "ButtonLink";

export { Button, ButtonLink, buttonVariants };
