import {
  EquipmentPrice,
  EquipmentPriceSubmit,
  Staff,
} from "@/components/new-equipment/interfaces";
import { API_URL, superFetch, workingHoursToWeekdays } from "@/lib/utils";

export async function fetchCreateEquipment(data: any) {
  const response = await superFetch(`${API_URL}/equipments`, {
    method: "POST",
    body: JSON.stringify(data),
  });
  return response;
}

export async function fetchUpdateEquipment(
  slug: string | undefined,
  data: any,
) {
  const response = await superFetch(`${API_URL}/equipments/${slug}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  return response;
}

export async function fetchTags(url: string) {
  const response = (await superFetch(`${API_URL}${url}`)) as any;
  return (response.data.tags || []).map((item: any) => ({
    ...item,
    value: String(item.id),
  }));
}

export async function fetchLoadTags(name: string) {
  const response = (await superFetch(`${API_URL}/tags?name=${name}`)) as any;
  return (response.data.tags || []).map((tag: any) => ({
    ...tag,
    value: String(tag.label),
  }));
}

export async function fetchManufacturers(url: string) {
  const response = (await superFetch(`${API_URL}${url}`)) as any;
  return (response.data.manufacturers || []).map((item: any) => ({
    ...item,
    value: String(item.id),
  }));
}

export async function fetchOrgs(url: string) {
  const response = (await superFetch(`${API_URL}${url}`)) as any;
  return (response.data.orgs || []).map((item: any) => ({
    ...item,
    label: item.name,
    value: String(item.slug),
    id: String(item.slug),
  }));
}

export async function fetchCalendars(orgID: string) {
  const response = (await superFetch(
    `${API_URL}/orgs/external-calendars?org_id=${orgID}`,
  )) as any;
  return (response.data || []).map((item: any) => ({
    ...item,
    value: String(item.id),
  }));
}

export async function fetchLoadManufacturers(name: string) {
  const response = (await superFetch(
    `${API_URL}/manufacturers?name=${name}`,
  )) as any;
  return (response.data.manufacturers || []).map((item: any) => ({
    ...item,
    value: String(item.id),
  }));
}

export function transformEquipmentData(values: any, state: any) {
  return {
    ...values,
    schedule_increment: Number(values.schedule_increment),
    maximum_reservation: Number(values.maximum_reservation),
    default_checkin_time: Number(values.default_checkin_time),
    default_checkout_time: Number(values.default_checkout_time),
    day_start_time:
      values.day_start_time_period === "AM"
        ? Number(values.day_start_time)
        : Number(values.day_start_time) + 1200,
    day_end_time:
      values.day_end_time_period === "AM"
        ? Number(values.day_end_time)
        : Number(values.day_end_time) + 1200,
    external_price_per_hour: Number(values.prices[1].price),
    tags: values.tags?.map((tag: any) => tag.value) || [],
    manufacturer_id: Number((values.manufacturer as any).value),
    manufacturer_name: (values.manufacturer as any).label,
    prices: values.prices.reduce(
      (acc: EquipmentPriceSubmit, item: EquipmentPrice) => ({
        ...acc,
        [item.name]: Number(item.price),
      }),
      { Affiliate: 10 },
    ),
    state,
    google_calendar_id: (values.google_calendar as any)?.value,
    google_calendar_name: (values.google_calendar as any)?.label,
    training_ids: values.training_ids,
    requires_subscription: values.requires_subscription === "true",
    price_included_in_subscription: values.price_included_in_subscription,
    working_hours: workingHoursToWeekdays(
      values.working_hours.map((item: any) => ({
        ...item,
        start_time: Number(item.start_time),
        end_time: Number(item.end_time),
      })),
    ),
    onsite_trainers: values.onsite_trainers?.map(
      (staff: Staff) => staff.user_id,
    ),
    buffer_time_between_bookings:
      Number(values.buffer_time_between_bookings) * 60,
  };
}

export async function fetchOrgMembers(url: string) {
  const response = (await superFetch(`${API_URL}${url}`)) as any;
  return (response.data || []).map((item: any) => ({
    user_id: item.id,
    profile_pic_url: item.profile_pic_url,
    first_name: item.first_name,
    last_name: item.last_name,
    role: item.role,
    hourly_rate: item.hourly_rate,
  }));
}

export async function updateHourlyRateForMembers(url: string, data: any) {
  const response = await superFetch(`${API_URL}${url}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  return response;
}
